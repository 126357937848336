// Generated by Framer (6124c77)

import { addFonts, cx, CycleVariantState, Link, SVG, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {o01n7sWQ7: {hover: true}};

const cycleOrder = ["o01n7sWQ7"];

const serializationHash = "framer-ShEbt"

const variantClassNames = {o01n7sWQ7: "framer-v-z851ky"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "o01n7sWQ7", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={"facebook.com"}><motion.a {...restProps} aria-label={"Facebook"} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-z851ky", className, classNames)} framer-1598ept`} data-framer-name={"fb"} layoutDependency={layoutDependency} layoutId={"o01n7sWQ7"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{opacity: 1, ...style}} variants={{"o01n7sWQ7-hover": {opacity: 0.5}}} {...addPropertyOverrides({"o01n7sWQ7-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><SVG className={"framer-7s3n18"} data-framer-name={"fb"} fill={"black"} intrinsicHeight={24} intrinsicWidth={24} layoutDependency={layoutDependency} layoutId={"XwbCmgB4z"} svg={"<svg width=\"24\" height=\"24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><g clip-path=\"url(#a)\"><path d=\"M12 0C5.373 0 0 5.373 0 12c0 5.628 3.875 10.35 9.101 11.647v-7.98H6.627V12H9.1v-1.58c0-4.084 1.849-5.978 5.859-5.978.76 0 2.072.15 2.608.298v3.324a15.38 15.38 0 0 0-1.386-.044c-1.967 0-2.728.745-2.728 2.683V12h3.92l-.673 3.667h-3.247v8.245C19.396 23.195 24 18.135 24 12c0-6.627-5.373-12-12-12Z\" fill=\"#fff\"/></g><defs><clipPath id=\"a\"><path fill=\"#fff\" d=\"M0 0h24v24H0z\"/></clipPath></defs></svg>"} withExternalLayout/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-ShEbt.framer-1598ept, .framer-ShEbt .framer-1598ept { display: block; }", ".framer-ShEbt.framer-z851ky { cursor: pointer; height: 24px; overflow: hidden; position: relative; text-decoration: none; width: 24px; }", ".framer-ShEbt .framer-7s3n18 { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 24px); left: 0px; position: absolute; right: 0px; top: 0px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 24
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"ilyTQqgwV":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerUpgeSZ9a0: React.ComponentType<Props> = withCSS(Component, css, "framer-ShEbt") as typeof Component;
export default FramerUpgeSZ9a0;

FramerUpgeSZ9a0.displayName = "fb";

FramerUpgeSZ9a0.defaultProps = {height: 24, width: 24};

addFonts(FramerUpgeSZ9a0, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})